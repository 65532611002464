import { css } from '@emotion/core';

export default css({
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    boxShadow: '0 1px 15px 0 rgba(0, 0, 0, 0.07)',
    zIndex: 10,
    '& .header-cce-nav': {
        width: '100%',
        height: 66,
        padding: '0px 40px',
        boxSizing: 'border-box',
        background: '#FFF',
        transition: 'all 0.5s ease',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .ccelogo': {
            width: 105,
            height: 45,
            marginRight: 50,
            '& img': {
                width: '100%',
                height: '100%'
            }
        },
        '& .nav-links': {
            display: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            '& > a': {
                fontSize: 14,
                color: '#1f300d',
                textTransform: 'uppercase',
                textDecoration: 'none',
                padding: '5px 0px',
                marginRight: 15,
                textAlign: 'center'
            },
            '& .active': {
                borderBottom: '2px solid #1f300d'
            },
        },
        '& .header-cce-button': {
            width: 50,
            height: 50,
            border: 'none',
            cursor: 'pointer',
            background: 'transparent',
        },
    },
    '& .header-cce-mininav': {
        width: 325,
        height: '100vh',
        padding: '20px 10px',
        boxSizing: 'border-box',
        background: '#272727',
        position: 'fixed',
        top: '0px',
        right: '0px',
        transition: 'right 0.5s ease',
        overflow: 'auto',
        '& > button': {
            width: 40,
            height: 40,
            background: 'transparent',
            border: 'none',
            fontSize: 28,
            color: '#FFF',
            marginBottom: 15,
            cursor: 'pointer'
        },
        '& a': {
            fontSize: 14,
            color: '#FFF',
            textTransform: 'uppercase',
            textDecoration: 'none',
            textAlign: 'right',
            padding: 15,
            marginBottom: 15,
            width: '100%',
            display: 'block',
            boxSizing: 'border-box',
            background: 'transparent',
            transition: 'background 0.2s ease',
            '&:hover': {
                background: 'rgba(255, 255, 255, 0.1)',
            }
        },
        '&--closed': {
            right: '-325px',
        },
        '& .footer-links': {
            borderTop: '1px solid rgba(255,255,255,0.2)',
            paddingTop: 20,
            '& > h4': {
                color: 'rgba(255,255,255,0.2)',
                textAlign: 'right',
                marginBottom: 20,
                padding: 15,
                boxSizing: 'border-box',
            }
        },
        '& .host-link': {
            width: 210,
            marginLeft: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            textDecoration: 'none',
        },
        '& .host-image': {
            width: 100,
        },
    },
    '@media only screen and (min-width: 1120px)': {
        '& .header-cce-nav': {
            justifyContent: 'center',
            '& .nav-links': {
                display: 'flex'
            },
            '& .header-cce-button': {
                display: 'none'
            }
        }
    }
});

import React from 'react';
import { Helmet } from 'react-helmet';

import Header from '../components/common/Header';
// import Footer from '../components/home/MainFooter';
import SubHeader from '../components/common/SubHeader';

import IntranetApp from '../components/intranet';

const Intranet = () => (
    <>
        <Helmet>
            <title>Comisión de Energía del CCE | Intranet</title>
        </Helmet>
        <main style={{ overflow: 'auto', height: '100vh' }}>
            <Header section="intranet" />
            <SubHeader title="No disponible" />
            {/* <SubHeader title="Intranet" /> */}
            {/*
            <IntranetApp />
            */}
        </main>
    </>
);

export default Intranet;

import { css } from '@emotion/core';

export default css({
    backgroundColor: '#f5333f',
    width: '100%',
    height: 90,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 66,
    '& > h2': {
        fontSize: 24,
        color: '#FFF',
        textAlign: 'center',
        textTransform: 'uppercase'
    },
    '@media only screen and (min-width: 1024px)': {
        '& > h2': {
            fontSize: 30,
        }
    }
});

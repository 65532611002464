import React, { useState } from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql, } from 'gatsby';

import MenuIcon from '../../../helpers/icons/menu';
import styles from './styles';

const Header = ({ section }) => {
    const [state, setState] = useState({
        menuVisible: false,
    });

    const {
        cce, cesp, cee
    } = useStaticQuery(graphql`
        query MiniHeaderQuery {
            cce: file(relativePath: { eq: "cce_original.png" }) {
                childImageSharp {
                    fluid(
                        quality: 80,
                        maxWidth: 180
                    ) {
                        ...GatsbyImageSharpFluid,
                        originalName
                    }
                }
            }
            cesp: file(relativePath: { eq: "cespedes.png" }) {
                childImageSharp {
                    fluid(
                        quality: 80,
                        maxWidth: 100
                    ) {
                    ...GatsbyImageSharpFluid,
                    originalName
                    }
                }
            }
            cee: file(relativePath: { eq: "cee-logo.png" }) {
                childImageSharp {
                    fluid(
                        quality: 80,
                        maxWidth: 100,
                        duotone: {
                            highlight: "#FFFFFF",
                            shadow: "#ffffff"
                        }
                    ) {
                    ...GatsbyImageSharpFluid,
                    originalName
                    }
                }
            }
        }
    `);

    return (
        <header css={styles} className="header-cce">
            <div className="header-cce-nav">
                <div className="ccelogo">
                    <img alt="Logo de la Comisión de Energía del CCE" src="/images/ccelogo-green.png" />
                </div>
                <nav className="nav-links">
                    <Link
                        to="/"
                        rel="nofollow noopener noreferrer"
                        className={section === 'home' ? 'active' : ''}
                    >
                        Inicio
                    </Link>
                    <Link
                        to="/quienessomos"
                        rel="nofollow noopener noreferrer"
                        className={section === 'identity' ? 'active' : ''}
                    >
                        Quiénes somos
                    </Link>
                    <Link
                        to="/miembros"
                        rel="nofollow noopener noreferrer"
                        className={section === 'members' ? 'active' : ''}
                    >
                        Miembros
                    </Link>
                    <Link
                        to="/lineastrabajo"
                        rel="nofollow noopener noreferrer"
                        className={section === 'worklines' ? 'active' : ''}
                    >
                        Líneas de Trabajo
                    </Link>
                    <Link
                        to="/eventos"
                        rel="nofollow noopener noreferrer"
                        className={section === 'events' ? 'active' : ''}
                    >
                        Eventos
                    </Link>
                    <Link
                        to="/infografias"
                        rel="nofollow noopener noreferrer"
                        className={section === 'infographics' ? 'active' : ''}
                    >
                        Infografías
                    </Link>
                    <Link
                        to="/noticias"
                        rel="nofollow noopener noreferrer"
                        className={section === 'news' ? 'active' : ''}
                    >
                        Noticias/Prensa
                    </Link>
                    <Link
                        to="/resumenes-regulatorios"
                        rel="nofollow noopener noreferrer"
                        className={section === 'resumenes-regulatorios' ? 'active' : ''}
                    >
                        Resúmenes Regulatorios
                    </Link>
                    {/*
                    <Link
                        to="/intranet"
                        rel="nofollow noopener noreferrer"
                        className={section === 'intranet' ? 'active' : ''}
                    >
                        Intranet
                    </Link>
                    */}
                </nav>
                <button
                    type="button"
                    className="header-cce-button"
                    onClick={() => setState((prev) => ({ ...prev, menuVisible: true }))}
                >
                    <MenuIcon color="#1f300d" />
                </button>
                <nav
                    className={
                        state.menuVisible ? 'header-cce-mininav' : 'header-cce-mininav header-cce-mininav--closed'
                    }
                >
                    <button
                        type="button"
                        onClick={() => setState((prev) => ({ ...prev, menuVisible: false }))}
                    >
                        x
                    </button>
                    <Link rel="nofollow noopener noreferrer" to="/">Inicio</Link>
                    <Link rel="nofollow noopener noreferrer" to="/quienessomos">Quiénes somos</Link>
                    <Link rel="nofollow noopener noreferrer" to="/miembros">Miembros</Link>
                    <Link rel="nofollow noopener noreferrer" to="/lineastrabajo">Líneas de Trabajo</Link>
                    <Link rel="nofollow noopener noreferrer" to="/eventos">Eventos</Link>
                    <Link rel="nofollow noopener noreferrer" to="/infografias">Infografías</Link>
                    <Link rel="nofollow noopener noreferrer" to="/noticias">Noticias/Prensa</Link>
                    {/* <Link rel="nofollow noopener noreferrer" to="/intranet">Intranet</Link> */}
                    <div className="footer-links">
                        <h4>Enlaces de interés</h4>
                        <a
                            className="host-link"
                            href="https://www.cce.org.mx/"
                            rel="nofollow noopener noreferrer"
                            target="_blank"
                        >
                            <Img
                                className="host-image"
                                fluid={cce.childImageSharp.fluid}
                                alt={cce.childImageSharp.fluid.originalName}
                            />
                            <p>CCE</p>
                        </a>
                        <a
                            className="host-link"
                            href="http://www.cespedes.org.mx/"
                            rel="nofollow noopener noreferrer"
                            target="_blank"
                        >
                            <Img
                                className="host-image"
                                fluid={cesp.childImageSharp.fluid}
                                alt={cesp.childImageSharp.fluid.originalName}
                            />
                            <p>CESPEDES</p>
                        </a>
                        <a
                            className="host-link"
                            href="https://cee.colmex.mx/"
                            rel="nofollow noopener noreferrer"
                            target="_blank"
                        >
                            <Img
                                className="host-image"
                                fluid={cee.childImageSharp.fluid}
                                alt={cee.childImageSharp.fluid.originalName}
                            />
                            <p>CEE</p>
                        </a>
                    </div>
                </nav>
            </div>
        </header>
    );
};

Header.propTypes = {
    section: PropTypes.string
};

Header.defaultProps = {
    section: 'home'
};

export default Header;

import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles';

const SubHeader = ({ title }) => (
    <div css={styles} className="subheader-cce">
        <h2>{title}</h2>
    </div>
);

SubHeader.propTypes = {
    title: PropTypes.string.isRequired
};

export default SubHeader;
import React from 'react';

const Download = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 20 20"
        fill="#db9d3a"
    >
        <path d="M17 12v5H3v-5H1v5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5z" />
        <path d="M10 15l5-6h-4V1H9v8H5l5 6z" />
    </svg>
);

export default Download;
